$(document).ready(function() {

    customHome();
    customBlogDetail();
    customWitcheryColumnWidths();
    customRealignment();
    initWineList();
    initAccoladesList();
    customCookieBar();
    initGuestbook();
});



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === "object") {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
          $.get(imgURL, function (data) {
              // Get the SVG tag, ignore the rest
              var $svg = $(data).find('svg');

              // Add replaced image's ID to the new SVG
              if (typeof imgID !== 'undefined') {
                  $svg = $svg.attr('id', imgID);
              }
              // Add replaced image's classes to the new SVG
              if (typeof imgClass !== 'undefined') {
                  $svg = $svg.attr('class', imgClass + ' replaced-svg');
              }

              // Remove any invalid XML tags as per http://validator.w3.org
              $svg = $svg.removeAttr('xmlns:a');

              // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
              if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                  $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
              }

              // Replace image with new SVG
              $img.replaceWith($svg);
          }, 'xml');
        }
    });
}



function customHome() {
    $('.home__item-slider-inner').slick({
        fade: true,
        arrows: true
    });
}

// Override
var initAccommodation = function () {
    if ( $('body').hasClass('theme-witchery') ) {
      $('.accommodation__image').slick({
          fade: true,
          dots: true,
          arrows: false
      });
    } else {
      $('.accommodation__image').slick({
          fade: true,
          arrows: true
      });
    }
};

// Override
var initHeader = function () {

    function throttle(fn, threshhold, scope) {
      threshhold || (threshhold = 250);
      var last,
          deferTimer;
      return function () {
        var context = scope || this;

        var now = +new Date,
            args = arguments;
        if (last && now < last + threshhold) {
          // hold on to it
          clearTimeout(deferTimer);
          deferTimer = setTimeout(function () {
            last = now;
            fn.apply(context, args);
          }, threshhold);
        } else {
          last = now;
          fn.apply(context, args);
        }
      };
    }

    // Detect if the header is over an image slider, if yes then give it a transparent background
    if ( $('.slider').length > 0 ) {
        setHeaderTransparency();
        $(window).on('scroll', setHeaderTransparency);
    }

    function setHeaderTransparency() {
        if (window.pageYOffset < 150) {
            $('.header').addClass('header--transparent');
        } else {
            $('.header').removeClass('header--transparent');
        }
    }

}

// Override
var initOtherRooms = function () {
    $('.other-rooms__carousel').slick({
        arrows: false,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}



function customBlogDetail() {
    $('.blog-detail__slider-inner').slick({
        fade: true,
        arrows: true
    });
}


function customWitcheryColumnWidths() {
    if ( $('body').hasClass('theme-witchery') ) {
        // Different widths of content and images needed for Witchery:
        $('.landing__item-content-image').addClass('large-7');
        $('.landing__item-content-inner').addClass('large-5');

        // $('.accommodation__image-wrapper').addClass('large-7');
        // $('.accommodation__content').addClass('large-5');

        if ( $('.accommodation').length > 0 ) {
            setTimeout(function () {
                $('.accommodation__image').slick('refresh');
            }, 600);
        }

        $('.offers__ctas').removeClass('large-3').addClass('large-4');
    }
}




// Fixing the arrow problem
var initGallery = function () {
    $('.gallery__item a').on('click', function () {
        var $clickedThumbnail = $(this);



        // Dynamically load the gallery slider into a reveal popup
        var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-image"><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content grid-x grid-margin-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div>';

        modal = modal.replace('{{src}}', $(this).attr('href'));
        modal = modal.replace('{{caption}}', $(this).data('title') || '');

        var cta = $(this).data('description') || '';
        if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
            cta += '<a href="' + $(this).data('book-link') + '" class="button button--green" target="_blank">' + ($(this).data('book-title') || 'Book Now') + '</a>';
        }
        modal = modal.replace('{{cta}}', cta);

        var $modal = modal;

        $('body').append($modal);

        $('#gallery__modal').foundation().on('closed.zf.reveal', function () {
            $('#gallery__modal').remove();
        });

        $('#gallery__modal').foundation('open');

        $('.gallery__modal-next').on('click', function () {
            $('#gallery__modal').foundation('close');
            $('#gallery__modal').remove();
            if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
                $clickedThumbnail.parent().next(':visible').find('a').trigger('click');
            } else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
                $clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
                $('#gallery__modal').foundation('close');
                $('#gallery__modal').remove();
            }
        });

        $('.gallery__modal-prev').on('click', function () {
            $('#gallery__modal').foundation('close');
            $('#gallery__modal').remove();
            if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
                $clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
            } else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
                $clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
                $('#gallery__modal').foundation('close');
                $('#gallery__modal').remove();
            }
        });

        return false;
    });

    refreshMasonry();
    $('.gallery').on('filter-changed', function () {
        setTimeout(refreshMasonry, 600);
    });

    Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);

    function refreshMasonry() {
        $('.gallery__grid').masonry({
            columnWidth: '.gallery__item-sizer',
            itemSelector: '.gallery__item',
            percentPosition: true,
            horizontalOrder: true
        });
    };

    // Remove the transition class after loading to avoid jumpy thumbnails
    setTimeout(function () {
        $('.gallery__item').removeClass('transition--2000');
    }, 2000);



    // Remove empty filter buttons (if not handled by the plugin)
    $('.filter__buttons button').each(function () {
        if ( $(this).text() == '' ) {
            $(this).remove();
        }
    });
}


function customRealignment() {
    Foundation.onImagesLoaded($('.accommodation__image img'), function () {
       $('.accommodation__image').slick('refresh');
    });

    if ( $('.accommodation').length > 0 ) {
        setTimeout(function () {
            $('.accommodation__image').slick('refresh');
        }, 1500);
    }
}


function initWineList() {
    $('.wine-list__accordion').accordiom({
        showFirstItem: false
    });
}

function initAccoladesList() {
    $('.accolades__accordion-inner').accordiom({
        showFirstItem: false
    });


    $('.accolades__carousel-inner').slick({
        fade: true,
        arrows: true
    });

}



function customCookieBar() {
    // Uncomment to always see the cookie bar. Handy for testing!
    //$('.cookie-bar').fadeIn();

    if ( $('.cookie-bar').length > 0 ) {
        if ( !Cookies.get('cookies_accepted') ) {
            $('.cookie-bar').fadeIn();
        }


        $('.cookie-bar__accept').on('click', function () {
            Cookies.set('cookies_accepted', 'true', {expires: 30});
            $('.cookie-bar').fadeOut();
        });
    }
}



function initGuestbook() {
    if ( $('.guestbook').length > 0 ) {

        var count = 0;
        $('.guestbook__caption').each(function() {
           $('.guestbook__index ul').append('<li><button data-index="' + count + '">' + $(this).text() + '</button></li>');
           count++;
        });
        $('.guestbook__index button').first().addClass('current');


        $('.guestbook__index button').on('click', function () {
            $('.guestbook__book-slider').slick('slickGoTo', $(this).data('index'));
        });


        $('.guestbook__book-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
           $('.guestbook__index button').removeClass('current');
           $('.guestbook__index button').eq(nextSlide).addClass('current');
        });


        $('.guestbook__book-slider').slick({
            arrows: true,
            fade: true,
            swipe: false
        });

        scrollPages();
        $(window).on('resize', scrollPages);
    }
}

function scrollPages() {
    // On mobile need to scroll each page over to the right side
    $('.guestbook__image').scrollLeft(640);
}
